<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <template>
      <v-card class="elevation-12">
        <v-toolbar   flat>
          <v-toolbar-title>Unfreeze Student Details</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-form>
             <v-row>
              <v-col  cols="12" sm="4" style="padding-left: 2%;">
                <v-text-field
                 v-model="user.prnno"     
                 label="Enter GR No./Name/Mobile No."
                 clearable outlined>
              </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" style="margin-top: 1%;">    
                 <center><v-btn class="mr-8 logi" color="primary" :loading="loaders" @click="init()">Fetch Student</v-btn></center>
               </v-col>
               <v-col v-if="learner_list.length != 0" cols="12" sm="3">
                <v-select 
                  placeholder="Selcet Learner"
                  outlined
                  clearable
                  required
                  :items="learner_list"
                  item-text="stud"
                  v-model="user.prnno"
                ></v-select>
               </v-col>
               <v-col v-if="learner_list.length != 0" cols="12" sm="2" style="margin-top: 1%;">    
                 <center><v-btn class="mr-8 logi" color="primary" @click="getstudentdetails()">Get Details</v-btn></center>
               </v-col>
             </v-row>
            
            
             <v-card outlined v-if="array.length > 0">
                <v-row>
                    <v-col cols="12" sm="1" >                         
                    </v-col>
                    <v-col cols="12" sm="4" > 
                        <strong> Name   : &nbsp; {{name}}</strong>
                    </v-col>
                   
                    <v-col cols="12" sm="4" > 
                        <strong> Email  : {{email}}</strong>
                    </v-col>
                </v-row>
             
                 <v-row>
                 <v-col cols="12" sm="1" >                         
                    </v-col>
                 <v-col cols="12" sm="4" > 
                     <strong> Program : {{program}}</strong>
                 </v-col>
                
                 <v-col cols="12" sm="4" > 
                     <strong> Year  : {{year}}</strong>
                 </v-col>
                
             </v-row>
                </v-card>
            
            
             <v-row v-if="array.length > 0" style="margin-left:25px;">
               <v-col cols="6" sm="4" >   
               <label>Academics details Freezed</label>
                      
                      <v-switch class="switchone" 
                                      v-model="academics" 
                                      @change="unfreezeStudentdetails('academics')"
                                      :labels="{checked:'On',unchecked:'Off'}"
                                      ></v-switch>
                      </v-col>
                       <v-col cols="6" sm="4" > 
                      <label>Personal details Freezed</label>
                      <v-switch class="switchone" 
                                      v-model="personal" 
                                      @change="unfreezeStudentdetails('personal')"
                                      :labels="{checked:'On',unchecked:'Off'}"
                                      ></v-switch>
                       </v-col>
                       <v-col cols="6" sm="4" > 
                       <label>Examination details Freezed</label>
                                    <v-switch class="switchone" 
                                      v-model="examination" 
                                      @change="unfreezeStudentdetails('examination')"
                                      :labels="{checked:'On',unchecked:'Off'}"
                                      ></v-switch>
                       </v-col>
                      
                       <v-col cols="6" sm="4" > 
                         <label>Is Placed</label>
                           <v-switch class="switchone" 
                                      v-model="isplaced" 
                                      @change="unfreezeStudentdetails('isplaced')"
                                      :labels="{checked:'On',unchecked:'Off'}"
                                      ></v-switch>
                       </v-col>
                      
                       <v-col cols="6" sm="4" > 
                           <label>Is Internship Received</label>
                                  <v-switch class="switchone" 
                                      v-model="isintern" 
                                      @change="unfreezeStudentdetails('isintern')"
                                      :labels="{checked:'On',unchecked:'Off'}"
                                      ></v-switch>
                       </v-col>
                      
              </v-row>
              <!-- <v-row v-if="array.length > 0">
               <v-col cols="6" sm="6" md="6">    
                 <center><v-btn class="mr-4" color="primary" @click="unfreezeStudentdetails()">Unfreeze</v-btn></center>
               </v-col>
              </v-row>  -->
              <v-divider></v-divider>

               <v-row v-if="array.length > 0" style="margin-left:25px;">
               
                <v-card-text>
                  <v-row>
                    <v-col cols="4" >
                          <label>Is Academic Details Freezed :</label>
                          <span v-if="academics == true" style="color:green;font-size:20px" class="mdi mdi-checkbox-marked-circle"></span><span v-else style="color:red;font-size:20px"  class="mdi mdi-close-circle"></span>
                         </v-col>
                   
                    <v-col cols="4" >
                          <label>Is Personal Details Freezed :</label>
                          <span v-if="personal == true" style="color:green;font-size:20px" class="mdi mdi-checkbox-marked-circle"></span><span v-else style="color:red;font-size:20px"  class="mdi mdi-close-circle"></span>
                         </v-col>
                    
                    <v-col cols="4" >
                          <label>Is Examination Details Freezed :</label>
                          <span v-if="examination == true" style="color:green;font-size:20px" class="mdi mdi-checkbox-marked-circle"></span><span v-else style="color:red;font-size:20px" class="mdi mdi-close-circle"></span>
                         </v-col>
                   
                    <v-col cols="4">
                          <label>Is Placed : </label>
                          <span v-if="isplaced == true" style="color:green;font-size:20px" class="mdi mdi-checkbox-marked-circle"></span><span v-else style="color:red;font-size:20px"  class="mdi mdi-close-circle"></span>
                         </v-col>
                   
                    <v-col cols="4">
                          <label>Is Internship Received :</label>
                          <span v-if="isintern == true" style="color:green;font-size:20px" class="mdi mdi-checkbox-marked-circle"></span><span v-else style="color:red;font-size:20px"  class="mdi mdi-close-circle"></span>
                         </v-col>
               
                  
                  </v-row>
                </v-card-text>
               
             </v-row>  
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      user: {
        prnno: "",
      },
      value: String,
      message: "",
      snackbar_msg: "",
      array:[],
      learner_list: [],
      color: "",
       name:"",
      email:"",
      year:"",
      grno:"",
      program:"",
      snackbar: false,
      isplaced:false,
      loaders: false,
      isintern:false,
      isyeardown:false,
      isgoinforhigherstudies:false,
      unfreezeExamination:false,
      unfreezePersonal:false,
      unfreezeAcademics:false,
    };
  },
  methods: {
    init() {
      if (this.user.prnno.length == 0) {
        this.showSnackbar("red", "Please Enter Gr Number / Name / Mobile Number");
      } else {
        this.loaders = true;
        var params = { grlist: this.user.prnno };
      axios.post("/LearnerData/getStudentList", params).then((res) => {
        if (res.data.msg == "200") {
          this.learner_list= res.data.learner_list;  
          this.loaders = false;
        }
        else if(res.data.msg == "NOTFOUNḌ"){
          this.loaders = false;
          this.showSnackbar("red", "Learner Not Found");
        }
      });
    }
    },
      showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
        unfreezeStudentdetails(item){
            const data = {
                    value:item,                  
                   reg_no:this.user.prnno,
                };
        
       axios
            .post("/LearnerData/unfreezeStudentdetails",data)
            .then((res) => {
              if (res.data.msg == "200") {
                
                this.showSnackbar("#4caf50", "Details Unfreezed Successfully."); // show snackbar on success

              
               
              } else {
                // this.editAllData.splice(this.editAllData);
                // this.showSnackbar("#b71c1c", "Record Already Present!!!");
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    },

     getstudentdetails(){
    //  alert("11810772 ")
            const data = {
                    reg_no:this.user.prnno,
                };
       axios
            .post("/LearnerData/getstudentdetails",data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.academics = res.data.academics;
                this.personal = res.data.personal;
                this.examination = res.data.examination;
                this.isplaced = res.data.isplaced;
                this.isintern = res.data.isintern;
                 this.name= res.data.name;
                this.email= res.data.email;
                this.year= res.data.year;
                this.program= res.data.program;
          this.array= res.data.array;
                //this.user.prnno="";
                //console.log("araaay");
                //console.log(this.array)
               
              } else {
                // this.editAllData.splice(this.editAllData);
                // this.showSnackbar("#b71c1c", "Record Already Present!!!");
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    },
   
  },
};
</script>  
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.tc-title {
  background-image: linear-gradient(-90deg, skyblue, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>